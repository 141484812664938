@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+Georgian:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&display=swap');

:root {
  --primary-color : #087EA4;
  --secondary-color: #FFAF36;
  --bg-red: #364954;
  --gray-line: #B7B8BB;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  font-family: 'Roboto';
}

img {
  image-rendering: -webkit-optimize-contrast;
}

.hamburger-menu {
  display: none;
  cursor: pointer;
}

.container {
  max-width: 1280px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0 150px;
}

ul, li {
  list-style: none;
}

a {
  display: inline-block;
}



.top-background-img {
  position: absolute;
  top:0;
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  z-index: -111;
}


.font-georgia {
  font-family: 'Noto Serif Georgian';
  font-size: 20px;
}

.point-text {
  color: var(--primary-color);
}

.line-breaker-container {
  width: 100%;
  margin: 100px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-title-container {
  width: 100%;
}

.main-title-text {
  font-size: 18px;
  font-weight: 'bold';
  text-align: center;
}

.sub-title-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin-top: 8px;
}

.sub-title-text {
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: var(--primary-color);
}

.arrow-container {
  margin-left: 5px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}



/* Navbar */
#navbar {
}

#navbar .navbar {
  justify-content: space-between;
  flex-direction: row;
  padding: 16px 150px;
}

.fixed-navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: rgba(255,255,255, 0.9);
}


.navbar .logo-menu-container {
  display: flex;
}

.navbar .nav-menu-container{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 30px;
}

.nav-menu-container li {
  margin-right: 30px;
}

.icons-container {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.icons-container .icon-menu {
  margin: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icons-container .theme-btn {
  background: black;
  padding: 5px;
  margin-left: 30px;
  border-radius: 50%;
}
/* End Navbar */



/* Home Page */
#home-page {
  padding: 70px 5%;
}

#home-page .home-page {
  width: 100%;
}


.home-page .hero-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.home-page .hero-text-container {
  margin-right: 50px;
}

.hero-text-container .hero-main-text,
.hero-text-container .hero-sub-text {
  text-align: center;
}

.hero-text-container .hero-sub-text {
  margin-top: 20px;
}

.hero-image-container img {
  border-radius: 50%;
}

.blog-section-container {
  margin-top: 40px;
  width: 100%;
}

.blog-post-top {
  padding-bottom: 20px;
  border-bottom: 1px solid var(--gray-line);
}

.blog-post-top-container {
  display: flex;
  width: 100%;
  max-height: 300px;
  overflow: hidden;
  
}

.blog-top-post-image {
  min-width: 60%;
  max-height: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.blog-top-post-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blog-top-post-content {
  display: flex;
  flex-direction: column;
  padding: 30px 20px;
}

.blog-tags-container {
  display: flex;
  flex-direction: row;
}

.blog-tags-container .blog-tag {
  margin-right: 5px;
  font-size: 12px;
  font-weight: 500;
  color: var(--primary-color);
}

.blog-title-container {
  margin-top: 8px;
}

.blog-top-post-content .blog-title-container .blog-main-title {
  --max-lines: 2;
    display: -webkit-box;
    -webkit-line-clamp: var(--max-lines);
    overflow: hidden;
    -webkit-box-orient: vertical;
}

.blog-title-container .blog-main-title {
  font-size: 24px;
  --max-lines: 1;
    display: -webkit-box;
    -webkit-line-clamp: var(--max-lines);
    overflow: hidden;
    -webkit-box-orient: vertical;
}

.blog-title-container .blog-sub-title {
  font-size: 16px;
  font-weight: 500;
  margin-top: 8px;
  --max-lines: 2;
  display: -webkit-box;
  -webkit-line-clamp: var(--max-lines);
  overflow: hidden;
  -webkit-box-orient: vertical;
}

.blog-addinfo-container {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
}

.blog-addinfo-container .blog-addinfo-date,
.blog-addinfo-container .blog-addinfo-length {
  font-size: 12px;
  margin-right: 10px;
  font-weight: 300;
}

.blog-post-bottom-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 20px;
}

.blog-post-bottom-container .blog-post-card-container {
  display: flex;
  width: 30%;
  flex-direction: column;
  margin-bottom: 20px;
}

.blog-post-card-container .blog-post-card-content {
  margin-top: 8px;
}

.blog-post-card-container .blog-post-card-content h1 {
  font-size: 18px;
}

.blog-post-card-container .blog-post-card-content h3 {
  font-size: 14px;
}

.blog-post-card-image {
  width: 100%;
  height: 250px;
  border-radius: 10px;
  overflow: hidden;
}
.blog-post-card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}



.projects-section-container {
  width: 100%;
  height: 100%;
  margin-top: 30px;
  margin-bottom: 100px;
}

.project-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 250px;
  overflow: hidden;
  background-color: var(--primary-color);
  border-radius: 10px;
  padding: 20px 50px 0 50px;
  margin-bottom: 15px;
}

.project-card-container.row-reverse {
  flex-direction: row-reverse;
  background-color: var(--secondary-color);
}

.project-card-container .project-card-image {
  height: 100%;
  display: flex;
  justify-content: center;
}

.project-card-container.row-reverse .project-card-image {
  height: 100%;
  margin-bottom: 20px;
}

.project-card-container .project-card-image img{
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.project-card-container .project-card-content {
  width: 60%;
  margin: 0 40px 30px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.project-card-container .project-card-content h1,
.project-card-container .project-card-content p{
  color: #fff;
}

.project-card-container .project-card-content p {
  margin-top: 7px;
  --max-lines: 4;
    display: -webkit-box;
    -webkit-line-clamp: var(--max-lines);
    overflow: hidden;
    -webkit-box-orient: vertical;
}

.project-card-button-container {
  display: flex;
  margin-top: 24px;
  justify-content: center;
  align-items: center;
}

.project-card-button-container h3 {
  color: #fff;
}

.featured-post-button-container {
  display: flex;
  align-items: center;
  margin-top: 30px;
}





/* End Home Page */

/* Blog Page */
#blog-page {

}

#blog-page .blog-page {
  
}

.blog-page .hero-container,
.contact-page .hero-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px;
}

.blog-page .hero-container .hero-sub-title,
.projects-page .hero-container .hero-sub-title,
.contact-page .hero-sub-title
 {
  color: var(--primary-color);
  font-size: 26px;
}

.blog-page .hero-container .hero-title,
.projects-page .hero-container .hero-title
{ 
  font-size: 32px;
  margin-top: 13px;
}

.featured-post-container {
  width: 100%;
  height: 100%;
  display: flex;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--gray-line);
}

.featured-post-container .featured-post-image {
  width: 50%;
  border-radius: 10px;
  overflow: hidden;
}

.featured-post-container .featured-post-image img {
  width: 100%;
  height: 100%;
}

.featured-post-container .featured-post-content {
  width: 50%;
  padding: 20px;
}

.featured-post-container .featured-post-content > h5 {
  display: inline-block;
  font-size: 20px;
  margin-bottom: 10px;
  padding-bottom: 0px;
  border-bottom:3px solid var(--gray-line);
  color: var(--bg-red);
}

.featured-post-container .featured-post-content .featured-post-title {
  font-size: 24px;
  margin-top: 13px;
}

.featured-post-container .featured-post-content .featured-post-sub-title {
  font-size: 16px;
  font-weight: 500;
  margin-top: 13px;
}

.blog-categories-container {
  margin-top: 20px;
  font-size: 16px;
  display: flex;
  flex-wrap: wrap;
}

.blog-categories-container .blog-category-container {
  background-color: var(--primary-color);
  padding: 5px 10px;
  margin: 5px 10px;
  border-radius: 30px;
  color: #fff;
}

.blog-posts-grid-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  /* justify-content: space-between; */
  /* flex-wrap: wrap; */
  margin: 40px 0;
}

.blog-posts-grid-container .blog-post-card-container {
  width: 100%;
  margin-bottom: 20px;
}
/* End Blog Page */

/* Projects Page */
#projects-page .projects-page {
  padding-bottom: 100px;
}

.projects-page .hero-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 0;
}

.projects-page .hero-container .hero-desc {
  font-size: 20px;
  text-align: center;
  font-weight: 300;
  margin-top: 10px;
}
/* End Projects Page */

/* Project Detail Screen */
#post-detail {
  
}

#post-detail .post-detail {
  padding: 100px 150px;
}

.post-detail-hero-section-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.post-detail-hero-section-container h1 {
  font-size: 60px;
  text-align: center;
}

.post-detail-hero-section-container p {
  width: 60%;
  text-align: center;
  font-size: 16px;
  font-weight: 300;
  margin-top: 10px;
}

.post-detail-hero-section-container h3 {
  margin-top: 10px;
}

.post-detail-main-image-container {
  width: 100%;
  height: 400px;
  padding: 30px 0;
  background-color: #fff;
  border-radius: 10px;
  margin-top: 30px;
}

.post-detail-main-image-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.post-detail-main-content-container {
  margin-top: 50px;
  border-radius: 10px;
  padding: 0 100px;
}

.post-section-container .section-image-container {
  width: 100%;
  height: 100%;
  margin-bottom: 30px;
}

.post-section-container .section-image-mobile-container {
  width: 100%;
  height: 500px;
  margin-bottom: 30px;
}

.post-section-container .section-image-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.post-section-container .section-image-mobile-container img {
  width: 100%;
    height: 100%;
    object-fit: contain;
}

.post-section-container h1 {
  font-size: 34px;
  border-bottom: 3px solid var(--primary-color);
  margin-bottom: 20px;
  margin-top: 50px;
  padding-bottom: 5px;
}

.post-section-container h2 {
  font-size: 24px;
  margin-top: 10px;
  margin-bottom: 10px;
  /* background-color: var(--secondary-color); */
  display: inline-block;
  padding: 5px 10px;
  border-left: 8px solid var(--secondary-color);
  /* border-radius: 5px; */
}

.post-section-container p {
  font-size: 20px;
  line-height: 32px;
  font-family: serif;
  margin-bottom: 20px;
}

.post-section-container .code-block-container {
  margin-bottom: 30px;
}

.post-section-container .bullet-items-container {
  margin-bottom: 20px;
  padding-left: 20px;
  border-left: 8px solid var(--gray-line);
}

.post-section-container .bullet-item {
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 18px;
  color: var(--primary-color);
}

/* End Project Detail Screen */

/* Contact Page */
#contact-page {
}

#contact-page .contact-page {
  height: 100%;
  padding-top: 50px;
  justify-content: flex-start;
  align-items: flex-start;
}

.contact-profile-container {
  width: 100%;
  height: 100%;
  display: flex;
}

.contact-profile-image-container {
  width: 300px;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.contact-profile-image-container img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.contact-profile-content-container {
  width: 60%;
  padding: 20px;
}
.contact-profile-content-container h1 {
  font-weight: 500;
  padding-bottom: 10px;
  border-bottom: 3px solid var(--primary-color);
}

.contact-profile-content-container h3 {
  margin-top: 5px;
}

.contact-profile-intro {
  font-size: 16px;
  font-weight: 300;
  margin-top: 10px;
  line-height: 1.2;
}


/* End Contact Page */



/* Footer */
#footer {
  position: relative;
  padding: 0 10%;
  height: 60px;
}

#footer .footer{
  height: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.footer h1 {
  font-size: 14px;
  font-weight: 400;
}

.footer .footer-icons-container {
  display: flex;
}

.footer .footer-icons-container .footer-icon-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
}
.footer-icons-container .footer-icon-menu .icon-container {
  display: flex;
  margin-right: 5px;
}

/* End Footer */


@media only screen and (max-width: 1260px) {

  .container {
    max-width: 100%;
    padding: 0 10px;
  }

  .top-background-img {
    position: absolute;
    top: 0;
    width: 100%;
    height: 30%;
    display: flex;
    justify-content: center;
    z-index: -111;
  }

  .line-breaker-container {
    margin: 50px 0;
  }

  #home-page {
    padding: 0;
  }

  #home-page .home-page {
    max-width: 100%;
  }

  .home-page .hero-container {
    flex-direction: column-reverse;
    align-items: center;
    margin-top: 30px;
  }

  .home-page .hero-text-container {
    margin-right: 0;
  }

  .hero-text-container .hero-main-text
    {
    font-size: 24px;
    margin-top: 20px;
  }

  .hero-text-container .hero-sub-text {
    font-size: 16px;
  }

  .blog-post-top-container {
    flex-direction: column;
    max-height: 100%;
  }

  .blog-post-bottom-container {
    
    flex-direction: column;
  }

  .blog-post-bottom-container .blog-post-card-container {
    width: 100%;
  }
  
  .hamburger-menu {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1111;
  }

  #navbar .navbar {
    padding: 16px;
    justify-content: space-between;
  }

  


  .navbar .nav-menu-container.active {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.95);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1001;
  }

  .navbar .logo-menu-container {
    position: relative;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .navbar .nav-menu-container {
    display: none;
    margin-left: 0;
  }


  .nav-menu-container li {
    margin: 0;
    padding: 15px 0;
    text-align: center;
  }

  .icons-container {
    position: absolute;
    right: 0;
  }


  .project-card-container,
  .project-card-container.row-reverse {
    height: 100%;
    flex-direction: column;
    padding: 20px;
  }

  .project-card-container .project-card-image {
    height: 200px;
    display: flex;
    justify-content: center;
  }

  .project-card-container.row-reverse .project-card-image {
    height: 200px;
    margin-bottom: 0;
  }

  .project-card-container .project-card-content {
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 0;
    margin-top: 20px;
  }

  .project-card-container .project-card-content p {
    text-align: center;
  }

  /* Blog page */
  .blog-page .hero-container,
  .contact-page .hero-container {
    padding: 0;
    margin: 30px 0;
  }

  .blog-page .hero-container .hero-sub-title,
  .projects-page .hero-container .hero-sub-title,
  .contact-page .hero-sub-title {
    font-size: 18px;
  }

  .blog-page .hero-container .hero-title,
  .projects-page .hero-container .hero-title {
    font-size: 20px;
    text-align: center;
  }

  .featured-post-container {
    flex-direction: column;
    margin-top: 20px;
  }

  .featured-post-container .featured-post-image {
    width: 100%;
  }

  .featured-post-container .featured-post-content {
    width: 100%;
    padding: 0;
    margin-top: 20px;
  }

  .featured-post-container .featured-post-content h5 {
    font-size: 18px;
    color: var(--primary-color);
  }

  .featured-post-container .featured-post-content .featured-post-title {
    font-size: 20px;
    margin-top: 13px;
  }

  .featured-post-container .featured-post-content .featured-post-sub-title {
    font-size: 14px;
    font-weight: 500;
    margin-top: 10px;
  }

  .blog-categories-container {   
    font-size: 12px;
  }
  .blog-categories-container .blog-category-container {
    padding: 3px 10px;
    margin: 3px 5px;
  }

  .blog-posts-grid-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 8px;
    margin: 20px 0;
  }

  /* Project Page */
  #projects-page .projects-page {
    padding-bottom: 0;
  }
  
  .projects-page .hero-container {
    padding: 0 50px;
    margin: 30px 0;
  }

  .projects-page .hero-container .hero-title {
    font-size: 24px;
  }

  .projects-page .hero-container .hero-desc {
    font-size: 13px;
    font-weight: 300;
    margin-top: 10px;
  }

  /* Contact Page */
  #contact-page .contact-page {
    height: 100%;
    padding-top: 30px;
  }

  .contact-profile-container {
    flex-direction: column;
  }

  .contact-profile-image-container {
    height: 300px;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
  }

  .contact-profile-content-container {
    width: 100%;
    
  }

  /* Post Detail Page */
  #post-detail .post-detail {
    padding: 0 10px;
  }

  .post-detail-hero-section-container h1 {
    font-size: 40px;
  }

  .post-detail-hero-section-container p {
    width: 100%;
    font-size: 14px;
    font-weight: 300;
  }

  .post-detail-main-image-container {
    width: 100%;
    height: 300px;
  }

  .post-detail-main-content-container {
    width: 100%;
    padding: 0;
  }

  .post-section-container h1 {
    font-size: 24px;
    margin-top: 0;
  }

  .post-section-container h2 {
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .post-section-container p {
    /* font-size: 18px; */
    font-size: 1.2rem;
    line-height: 22px;
    font-family: serif;
    margin-bottom: 20px;
  }

  .post-section-container .bullet-item {
    font-size: 16px;
  }

  .post-section-container .code-block-container {
    width: 100%;
  }

  /* Footer */
  #footer {
    padding: 0 10px;
  }

  .footer .footer-icons-container .footer-icon-menu {
    margin-right: 0;
    margin-left: 20px;
  }
}